import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Form,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import {
  useCreateOtherTimeType,
  useRegenerateOtherTimeType,
  useUpdateOtherTimeType,
} from "../api/OtherTimeTypes.hooks";

import { faInfoCircle, faSync } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { components, sharedHelper } from "@crewos/shared";

import { useGetAllWorkTimeTypes } from "@crewos/worktimes";

const {
  Loader,
  ConfirmationModal,
  ConfirmationModalWithInput,
  RulesModal,
  CustomCheckbox,
  TooltipItem,
} = components;

const OtherTimeTypeModal = ({ otherTimeType = {}, onSubmit, onClose }) => {
  // State variables for form fields
  const [shortName, setShortName] = useState(otherTimeType.shortName || "");
  const [name, setName] = useState(otherTimeType.name || "");
  const [billingType, setBillingType] = useState(
    otherTimeType.billingType || ""
  );
  const [autoGeneration, setAutoGeneration] = useState(
    otherTimeType.autoGeneration
  );
  const [isAppEnabled, setIsAppEnabled] = useState(otherTimeType.isAppEnabled);
  const [generationRules, setGenerationRules] = useState(
    otherTimeType.generationRules || []
  );
  const [editGenerationRulesModal, setEditGenerationRulesModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();
  const [confirmationModalWithInput, setConfirmationModalWithInput] =
    useState();

  const [workTimeTypes, setWorkTimeTypes] = useState([]);

  const { get: getWorkTimeTypes, data: workTimeTypesData } =
    useGetAllWorkTimeTypes();

  useEffect(() => {
    getWorkTimeTypes();
  }, [getWorkTimeTypes]);

  useEffect(() => {
    if (workTimeTypesData) {
      setWorkTimeTypes(workTimeTypesData);
    }
  }, [workTimeTypesData]);

  // Hooks for creating and updating other time types
  const {
    isLoading: isLoadingCreateOtherTimeType,
    mutate: createOtherTimeType,
    data: createOtherTimeTypeData,
  } = useCreateOtherTimeType();

  const {
    isLoading: isLoadingUpdateOtherTimeType,
    update: updateOtherTimeType,
    data: updateOtherTimeTypeData,
  } = useUpdateOtherTimeType();

  const {
    isLoading: isLoadingRegenerateOtherTimeType,
    regenerate: regenerateOtherTimeType,
    data: regenerateOtherTimeTypeData,
  } = useRegenerateOtherTimeType();

  // Effect to handle successful creation
  useEffect(() => {
    if (createOtherTimeTypeData) {
      sharedHelper.successToast(`Other time type created`);
      onSubmit();
    }
  }, [createOtherTimeTypeData, onSubmit]);

  // Effect to handle successful update
  useEffect(() => {
    if (updateOtherTimeTypeData) {
      sharedHelper.successToast(`Other time type saved`);
      onSubmit();
    }
  }, [updateOtherTimeTypeData, onSubmit]);

  // Effect to handle successful update
  useEffect(() => {
    if (regenerateOtherTimeTypeData) {
      sharedHelper.successToast(`Other times regenerated`);
    }
  }, [updateOtherTimeTypeData, regenerateOtherTimeTypeData]);

  // Handler for form submission
  const doSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      name,
      shortName,
      billingType,
      autoGeneration: Boolean(autoGeneration),
      isAppEnabled: Boolean(isAppEnabled),
      generationRules,
    };
    if (otherTimeType.id) {
      await updateOtherTimeType({
        id: otherTimeType.id,
        ...payload,
      });
    } else {
      await createOtherTimeType(payload);
    }
  };

  const onDeleteGenerationRule = (index) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        setConfirmationModal();
        generationRules.splice(index, 1);
        setGenerationRules([...generationRules]);
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Generation Rule",
      body: `<span class="text-center">Do you confirm you want to delete this rule?</span>`,
    });
  };

  const onRegenerate = () => {
    setConfirmationModalWithInput({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async (overwriteOverwritten) => {
        setConfirmationModalWithInput();
        regenerateOtherTimeType({ id: otherTimeType.id, overwriteOverwritten });
      },
      onClose: () => {
        setConfirmationModalWithInput();
      },
      title: "Regenerate Other Times",
      body: `
        <p class="text-center small">This action will regenerate other times for past dates for all employees using current rules.</p>
        <span class="small">Check the option below if you also want to overwrite the overwritten values</span>
      `,
      confirmText: "Overwrite overwritten values",
      confirmType: "check",
    });
  };

  const isLoading =
    isLoadingCreateOtherTimeType ||
    isLoadingUpdateOtherTimeType ||
    isLoadingRegenerateOtherTimeType;

  return editGenerationRulesModal ? (
    <RulesModal
      entity={"Hours"}
      workTimeTypes={workTimeTypes}
      defaultRule={editGenerationRulesModal}
      onSubmit={(generationRule) => {
        setEditGenerationRulesModal();
        if (generationRule.index !== undefined) {
          generationRules.splice(generationRule.index, 1, generationRule);
        } else {
          generationRules.push(generationRule);
        }
        setGenerationRules([...generationRules]);
      }}
      onClose={() => setEditGenerationRulesModal()}
    />
  ) : confirmationModal ? (
    <ConfirmationModal {...confirmationModal} />
  ) : confirmationModalWithInput ? (
    <ConfirmationModalWithInput {...confirmationModalWithInput} />
  ) : (
    <Modal isOpen={true} toggle={onClose}>
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {otherTimeType.id ? "Edit Other Time Type" : "Add Other Time Type"}
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {isLoading && <Loader size="sm" />}
          {!isLoading && (
            <>
              <FormGroup>
                <Label>
                  <span>Name</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  maxLength="50"
                  type="text"
                  placeholder="Enter the name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <span>Short Name</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  maxLength="50"
                  type="text"
                  placeholder="Enter the short name"
                  name="shortName"
                  value={shortName}
                  onChange={(e) => setShortName(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label className="d-flex align-items-center">
                  <span>Billing Type</span>
                  <span className="text-danger ms-1">*</span>
                  <div className="ms-2">
                    <TooltipItem
                      id="tooltip-1"
                      title={`<span>
                              Define how hours affects the employee hours in the payroll
                              report<br/>
                              This is retroactive, if changed it will affect all other times of type in the past too
                            </span>`}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="text-primary"
                        style={{ paddingBottom: "1px" }}
                      />
                    </TooltipItem>
                  </div>
                </Label>
                <select
                  className="form-control-redesign px-0"
                  required={true}
                  type="select"
                  name="billingSelect"
                  id="billingSelect"
                  data-testid="billing-select"
                  value={billingType || ""}
                  onChange={(evt) => setBillingType(evt.currentTarget.value)}
                >
                  <option value="">Select type</option>
                  <option value="ADD">ADD</option>
                  <option value="IGNORE">IGNORE</option>
                  <option value="SUBSTRACT">SUBSTRACT</option>
                </select>
              </FormGroup>
              <FormGroup>
                <ListGroup className="mt-3">
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center cursor-pointer"
                    onClick={() => setAutoGeneration(!autoGeneration)}
                  >
                    <div className="flex-shrink-0">Auto Generation</div>
                    <div className="min-width-50 d-flex justify-content-end align-items-center">
                      <div className="d-flex align-items-center me-3">
                        {otherTimeType.id && autoGeneration ? (
                          <TooltipItem
                            id="tooltip-2"
                            title={`<span>
                              Generate all employees for past dates
                            </span>`}
                            className="me-3"
                          >
                            <FontAwesomeIcon
                              onClick={onRegenerate}
                              icon={faSync}
                              className="text-primary"
                            />
                          </TooltipItem>
                        ) : null}
                        <TooltipItem
                          id="tooltip-2"
                          title={`<span>
                              Enables auto generation of other times of type using the rules<br/>
                              Auto generation is not retroactive. Will auto generate based on data entered from the day the rules and flag are set
                            </span>`}
                        >
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="text-primary"
                            style={{ paddingBottom: "1px" }}
                          />
                        </TooltipItem>
                      </div>
                      <CustomCheckbox checked={autoGeneration} />
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </FormGroup>
              <FormGroup>
                <ListGroup className="mt-3">
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center cursor-pointer"
                    onClick={() => setIsAppEnabled(!isAppEnabled)}
                  >
                    <div className="flex-shrink-0">Is App Enabled</div>
                    <div className="min-width-50 d-flex justify-content-end align-items-center">
                      <div className="me-3">
                        <TooltipItem
                          id="tooltip-3"
                          title={`<span>
                              Enables other times of type to be managed by APP users<br/>
                              Not implemented yet
                            </span>`}
                        >
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="text-primary"
                            style={{ paddingBottom: "1px" }}
                          />
                        </TooltipItem>
                      </div>
                      <CustomCheckbox checked={isAppEnabled} />
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </FormGroup>
              <FormGroup>
                <ListGroup>
                  <ListGroupItem className="d-flex justify-content-between align-items-center fw-bold bg-graylight">
                    <span>Rules</span>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => setEditGenerationRulesModal(true)}
                    >
                      Create
                    </Button>
                  </ListGroupItem>
                  {generationRules.length ? (
                    generationRules
                      .sort((x, y) => x.precedence - y.precedence)
                      .map((generationRule, index) => (
                        <ListGroupItem
                          key={index}
                          className="d-flex justify-content-between align-items-center cursor-pointer"
                        >
                          <span>{`${generationRule.precedence} - ${generationRule.name}`}</span>
                          <div className="d-flex align-items-center justify-content-end">
                            <Button
                              size="sm"
                              color="primary"
                              onClick={() =>
                                setEditGenerationRulesModal({
                                  ...generationRule,
                                  index,
                                })
                              }
                            >
                              Edit
                            </Button>
                            <Button
                              color="danger"
                              className="ms-2"
                              size="sm"
                              onClick={() => onDeleteGenerationRule(index)}
                            >
                              Delete
                            </Button>
                          </div>
                        </ListGroupItem>
                      ))
                  ) : (
                    <ListGroupItem className="d-flex justify-content-center align-items-center cursor-pointer">
                      No rules added
                    </ListGroupItem>
                  )}
                </ListGroup>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          {isLoading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default OtherTimeTypeModal;
