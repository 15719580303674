import React, { useEffect, useState } from "react";

import { Button, Table, Collapse } from "reactstrap";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { components, sharedHelper, data } from "@crewos/shared";

import { useDeleteCrew } from "../api/Crews.hooks";
import { ACTIONS, useCrews } from "../providers/crewsProvider";
import { crewHelper } from "../helpers/crewHelper";
import WorkOrderCrewIndicators from "./WorkOrderCrewIndicators";
import CrewModal from "./CrewModal";

const { Loader, ConfirmationModal, InformationModal } = components;

const { useAuth } = data;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";
const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const CREWS_PACKAGE = "crews";

const Crew = ({ crew }) => {
  const [authContext] = useAuth();
  const [, setCrewsContext] = useCrews();

  const [loading, setLoading] = useState();

  const [isOpen, setIsOpen] = useState(true);

  const [crewModal, setCrewModal] = useState();

  const [informationModal, setInformationModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const { data: deleteCrewData, deleteCrew } = useDeleteCrew();

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  useEffect(() => {
    if (deleteCrewData) {
      setLoading();
      sharedHelper.successToast("Crew deleted.");
      setCrewsContext({
        action: ACTIONS.REFRESH,
      });
    }
  }, [deleteCrewData, setCrewsContext]);

  const onDeleteCrew = () => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        setConfirmationModal();
        setLoading(true);
        await deleteCrew(crew.id);
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Crew",
      body: `<span class="text-center">Do you confirm you want to delete this ${
        directAssignationEnabled ? "assignment" : "crew"
      } including all it's associated data?</span>`,
    });
  };

  const onCrewHistory = (crew) => {
    return setInformationModal({
      title: "Crew History",
      rawBody: true,
      size: "xl",
      onClose: () => setInformationModal(),
      body: (
        <Table
          className="col-12 px-0 mb-0 overflow-hidden"
          striped
          size="small"
        >
          <thead>
            <tr className="bg-graylight small">
              <th>Name</th>
              <th>Role</th>
              <th>Crew Lead</th>
              <th>From</th>
              <th>To</th>
            </tr>
          </thead>
          <tbody className="small">
            {crew.employeeCrews
              .sort(sharedHelper.sortEmployeeCrew)
              .map((employeeCrew) => (
                <tr key={employeeCrew.id}>
                  <td>{`${employeeCrew.employee?.firstName} ${employeeCrew.employee?.lastName}`}</td>
                  <td>{employeeCrew.role.name}</td>
                  <td>{employeeCrew.isLead ? "Yes" : "No"}</td>
                  <td>{sharedHelper.formatDateTime(employeeCrew.createdAt)}</td>
                  <td>
                    {employeeCrew.disabledAt
                      ? sharedHelper.formatDateTime(employeeCrew.disabledAt)
                      : "Present"}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ),
    });
  };

  const employeesByRoles = crewHelper.getEmployeesByRole(crew);

  const crewLead = Object.values(employeesByRoles)
    .flatMap((e) => e)
    .find((entry) => entry.isLead)?.employee;

  return (
    <div className="col-12 mt-3 p-3 border-radius-default border" key={crew.id}>
      <div className="d-flex justify-content-between align-items-center w-100">
        {!directAssignationEnabled ? (
          <div className="fw-bold col-6">
            {crewLead ? sharedHelper.getName(crewLead) : "No Lead"}
          </div>
        ) : null}
        {loading ? (
          <Loader size="sm" align="end" color="white" />
        ) : !directAssignationEnabled ? (
          <div className="col-6 d-flex justify-content-end align-items-center">
            <Button
              color="none"
              className="ms-2 text-primary px-2 d-flex align-items-center"
              size="sm"
              onClick={() => setCrewModal(true)}
            >
              Edit
            </Button>
            <Button
              color="none"
              className="ms-2 text-primary px-2 d-flex align-items-center"
              size="sm"
              onClick={() => onCrewHistory(crew)}
            >
              History
            </Button>
            <Button
              color="none"
              className="ms-2 text-primary px-2 d-flex align-items-center"
              size="sm"
              onClick={onDeleteCrew}
            >
              Delete
            </Button>
            <div
              className="d-flex align-items-center justify-content-end ms-2"
              onClick={() => setIsOpen(!isOpen)}
            >
              <div className="cursor-pointer d-flex align-items-start">
                <small
                  className="text-primary fw-bold"
                  data-testid="view-more-less"
                >
                  View {isOpen ? "less" : "more"}
                </small>
                <FontAwesomeIcon
                  className={`ms-2 ${isOpen ? "" : "rotate"}`}
                  icon={faChevronDown}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Collapse isOpen={Boolean(isOpen)}>
        {crew.employeeCrews.length ? (
          <div
            className={`d-flex flex-grow-1 flex-wrap ${
              !directAssignationEnabled ? "mt-3" : 0
            }`}
          >
            {crew.employeeCrews
              .filter((employeeCrew) =>
                crewHelper.isEmployeeCrewActiveOnDate(
                  employeeCrewPastDatesEnabled,
                  employeeCrew
                )
              )
              .sort(sharedHelper.sortEmployeeCrew)
              .map((employeeCrew) => (
                <div className="col-6 col-md-3 px-2 my-1" key={employeeCrew.id}>
                  <div
                    className="d-flex align-items-center justify-content-between px-3 py-2 box-shadow-darker border-radius-default"
                    key={employeeCrew.id}
                  >
                    <div className="d-flex flex-column">
                      <span>{sharedHelper.getName(employeeCrew.employee)}</span>
                      <small className="text-muted">
                        {employeeCrew.role.name}
                      </small>
                    </div>
                    <WorkOrderCrewIndicators
                      entry={employeeCrew}
                      onDeleteCrew={onDeleteCrew}
                    />
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="mb-4 text-center small text-muted">Empty Crew</div>
        )}
      </Collapse>
      {informationModal ? (
        <InformationModal {...informationModal} />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : crewModal ? (
        <CrewModal
          defaultCrew={crew}
          onClose={() => setCrewModal(false)}
          onSubmit={() => {
            setCrewModal(false);
            setCrewsContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : null}
    </div>
  );
};

export default Crew;
