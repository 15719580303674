import React, { useEffect, useState } from "react";

import { ListGroup, ListGroupItem, Button } from "reactstrap";

import { sharedHelper, components } from "@crewos/shared";

import CustomerLocationModal from "../CustomerLocationModal";
import { useDeleteCustomerLocation } from "../../api/Customers.hooks";

const { ConfirmationModal } = components;

const CustomerLocations = ({
  customer,
  setCustomerLocationContactsModal,
  refresh,
  setRefresh,
}) => {
  const [editModal, setEditModal] = useState();
  const [createModal, setCreateModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const { data: deleteCustomerLocationData, deleteCustomerLocation } =
    useDeleteCustomerLocation();

  useEffect(() => {
    if (deleteCustomerLocationData) {
      sharedHelper.successToast(`Customer location deleted`);
      setRefresh((prev) => !prev);
    }
  }, [deleteCustomerLocationData, setRefresh]);

  const onEdit = (customer) => setEditModal(customer);

  const onDelete = (customerLocation) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal();
        deleteCustomerLocation(customerLocation.id);
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Delete Customer Location",
      body: `Are you sure you want to delete ${sharedHelper.getAddress(
        customerLocation
      )}?`,
      confirmColor: "primary",
    });
  };

  return (
    <div className="col-12 col-lg-6 px-2">
      <ListGroup flush className="col-12 px-0">
        <div className="col-12 d-flex justify-content-between align-items-center px-3 py-2 bg-graylight border-0 border-radius-default fw-bold text-center">
          <span>Customer Locations</span>
          <Button
            size="sm"
            color="primary"
            onClick={() => setCreateModal(true)}
          >
            Create
          </Button>
        </div>
        {!customer.locations?.length ? (
          <ListGroupItem
            className="text-muted small border-bottom py-2 px-0 mx-3 d-flex justify-content-center align-items-center"
            tag="div"
            key="1"
          >
            No locations
          </ListGroupItem>
        ) : (
          customer.locations.map((location, index) => (
            <ListGroupItem
              className="border-bottom py-2 px-0 mx-3 d-flex justify-content-between align-items-center"
              tag="div"
              key={index + 1}
            >
              <span className="pe-2">{sharedHelper.getAddress(location)}</span>
              <div className="text-end flex-shrink-0">
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => setCustomerLocationContactsModal(location)}
                >
                  See Contacts
                </Button>
                <Button
                  color="none"
                  className="mx-2 text-primary"
                  size="sm"
                  onClick={() => onEdit(location)}
                >
                  Edit
                </Button>
                <Button
                  className="text-danger"
                  color="none"
                  size="sm"
                  onClick={() => onDelete(location)}
                >
                  Delete
                </Button>
              </div>
            </ListGroupItem>
          ))
        )}
      </ListGroup>
      {editModal ? (
        <CustomerLocationModal
          customerLocation={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : createModal ? (
        <CustomerLocationModal
          customerLocation={{ customerId: customer.id, isActive: true }}
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </div>
  );
};

export default CustomerLocations;
