import { useCallback } from "react";
import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const endpoint = `/api/other-time`;

export const useGetOtherTimes = () => {
  return useApiGet(endpoint);
};

export const useCreateOtherTime = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateOtherTime = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (otherTime) => {
      await mutate(otherTime);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useRecalculateOtherTime = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, `${endpoint}/re`);

  const update = useCallback(
    async (otherTime) => {
      await mutate(otherTime);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useBulkUpdateOtherTime = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/bulk`
  );

  const bulkUpdate = useCallback(
    async (otherTimes) => {
      await mutate(otherTimes);
    },
    [mutate]
  );

  return { ...rest, bulkUpdate };
};

export const useDeleteOtherTime = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteOtherTime = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteOtherTime };
};

export const useBulkDeleteOtherTime = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${endpoint}/bulk`
  );

  const bulkDeleteOtherTimes = useCallback(
    async (otherTimes) => {
      await mutate(otherTimes);
    },
    [mutate]
  );

  return { ...rest, bulkDeleteOtherTimes };
};
