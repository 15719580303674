import React, { useMemo, useState, useCallback, useEffect } from "react";

import { components, sharedHelper, data } from "@crewos/shared";

import { useNavigate } from "react-router-dom";

import moment from "moment";

import { faInfoCircle, faSync } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
} from "reactstrap";

import { EditWorkTimeModal } from "@crewos/worktimes";
import { EditTravelTimeModal } from "@crewos/traveltimes";
import {
  EditOtherTimeModal,
  useRecalculateOtherTime,
} from "@crewos/othertimes";

import { useGetPayroll } from "../api/Reports.hooks";

const dateFormat = "MM/DD";

const TRAVEL_TIMES_PACKAGE = "traveltimes";
const WORK_TIMES_PACKAGE = "worktimes";

const START_END_TRAVEL_TIME_SETTING = "START_END_TRAVEL_TIME_SETTING";
const START_END_WORK_TIME_SETTING = "START_END_WORK_TIME_SETTING";

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const {
  AdvanceTable,
  InformationModal,
  ConfirmationModal,
  TooltipItem,
  Loader,
} = components;

const { useAuth } = data;

const PayrollReportDateDetail = ({ employeeId, date, isTotal, onClose }) => {
  const [authContext] = useAuth();

  const { data: reportData, isLoading, get: getPayroll } = useGetPayroll();

  const { update: recalculateOtherTime, data: recalculateOtherTimeData } =
    useRecalculateOtherTime();

  const isSuperAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  const navigate = useNavigate();
  const [data, setData] = useState();
  const [refresh, setRefresh] = useState();

  const [touched, setTouched] = useState();
  const [editTravelTimeModal, setEditTravelTimeModal] = useState();
  const [editWorkTimeModal, setEditWorkTimeModal] = useState();
  const [editOtherTimeModal, setEditOtherTimeModal] = useState();

  const [informationModal, setInformationModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  useEffect(() => {
    const params = {
      employeeId,
    };
    if (isTotal) {
      params.weekStart = date;
    } else {
      params.date = date;
    }
    getPayroll(params);
  }, [getPayroll, refresh, date, isTotal, employeeId]);

  useEffect(() => {
    if (reportData && reportData.length) {
      setData(reportData[0]);
    }
  }, [reportData, setData]);

  const onSubmitTimes = useCallback(() => {
    setRefresh((refresh) => !refresh);
    setTouched(true);
  }, []);

  useEffect(() => {
    if (recalculateOtherTimeData) {
      onSubmitTimes();
    }
  }, [recalculateOtherTimeData, onSubmitTimes]);

  const handleClose = useCallback(() => onClose(touched), [onClose, touched]);

  const onSeeWODetails = (workOrderId) =>
    navigate(`/workorders/details/${workOrderId}`);

  const onSeeCustomerDetails = (customerId) =>
    navigate(`/customers/details/${customerId}`);

  const onEditEntry = (entry) => {
    const time = rawTimes.find((time) => time.id === entry.id);
    if (time.type === "Travel") {
      setEditTravelTimeModal(time);
    } else if (time.date) {
      //other time
      setEditOtherTimeModal(time);
    } else {
      setEditWorkTimeModal(time);
    }
  };

  const onOtherTimeInfo = (generationRule) => {
    setInformationModal({
      title: "Other Time Details",
      rawBody: true,
      size: "xl",
      onClose: () => setInformationModal(),
      body: (
        <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
          <thead>
            <tr className="bg-graylight small">
              <th>Rule</th>
              <th>Precedence</th>
              <th>Condition</th>
              <th>Hours</th>
              <th>Dates</th>
            </tr>
          </thead>
          <tbody className="small">
            <tr>
              <td>{generationRule.name}</td>
              <td>{generationRule.precedence}</td>
              <td>
                <code>{generationRule.condition}</code>
              </td>
              <td>{generationRule.amount}</td>
              <td>
                {generationRule.startDate
                  ? `${generationRule.startDate} to ${generationRule.endDate}`
                  : "Open Dates"}
              </td>
            </tr>
          </tbody>
        </Table>
      ),
    });
  };

  const onRecalculateOtherTime = (otherTimeId) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        setConfirmationModal();
        recalculateOtherTime({ id: otherTimeId });
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Recalculate Time Entry",
      body: `Are you sure you want to recalculate this time entry using current rules?`,
    });
  };

  const startEndTimeSettingEnabled = useMemo(
    () =>
      sharedHelper.isSettingEnabled(
        authContext.userData?.packages,
        TRAVEL_TIMES_PACKAGE,
        START_END_TRAVEL_TIME_SETTING
      ) ||
      sharedHelper.isSettingEnabled(
        authContext.userData?.packages,
        WORK_TIMES_PACKAGE,
        START_END_WORK_TIME_SETTING
      ),
    [authContext.userData]
  );

  const totalColumns = [
    {
      accessor: "Total Hours",
      header: "Total Hours",
      headerProps: { className: isTotal ? "text-center" : "text-end" },
      cellProps: { className: isTotal ? "text-center" : "text-end" },
      type: "number",
      Cell: (rowData) => {
        return rowData.row.totalHours;
      },
    },
  ];

  const detailColumns = [
    {
      accessor: "Work Order",
      header: "Work Order",
      headerProps: { className: "text-start" },
      cellProps: { className: "text-start" },
      Cell: (rowData) => {
        const isOtherTime = rowData.row.isOtherTime;
        return (
          <span
            className={isOtherTime ? "" : `text-link`}
            data-value={rowData.row.workOrder}
            data-testid="work-order-link"
            onClick={() =>
              isOtherTime ? null : onSeeWODetails(rowData.row.workOrderId)
            }
          >
            {rowData.row.workOrder}
          </span>
        );
      },
    },
    {
      accessor: "Customer",
      header: "Customer",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-center" },
      Cell: (rowData) => {
        const isOtherTime = rowData.row.isOtherTime;
        return (
          <span
            className={isOtherTime ? "" : `text-link`}
            data-value={rowData.row.customer}
            data-testid="customer-link"
            onClick={() =>
              isOtherTime ? null : onSeeCustomerDetails(rowData.row.customerId)
            }
          >
            {rowData.row.customer}
          </span>
        );
      },
    },
    {
      accessor: "Type",
      header: "Type",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-center" },
      Cell: (rowData) => {
        return rowData.row.type;
      },
    },
  ];

  if (startEndTimeSettingEnabled) {
    detailColumns.push({
      accessor: "Start Time",
      header: "Start Time",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-center" },
      type: "string",
      Cell: (rowData) => {
        const startTime = rowData.row.startTime;
        const isOtherTime = rowData.row.isOtherTime;
        const value = moment(startTime).isValid()
          ? sharedHelper.formatDateTime(startTime, "MM/DD, h:mm a")
          : "-";
        return (
          <span
            className={isOtherTime ? "" : `text-link`}
            data-value={value}
            onClick={() => (isOtherTime ? null : onEditEntry(rowData.row))}
          >
            {value}
          </span>
        );
      },
    });
    detailColumns.push({
      accessor: "End Time",
      header: "End Time",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-center" },
      type: "string",
      Cell: (rowData) => {
        const endTime = rowData.row.endTime;
        const isOtherTime = rowData.row.isOtherTime;
        const value = moment(endTime).isValid()
          ? sharedHelper.formatDateTime(endTime, "MM/DD, h:mm a")
          : "-";
        return (
          <span
            className={isOtherTime ? "" : `text-link`}
            data-value={value}
            onClick={() => (isOtherTime ? null : onEditEntry(rowData.row))}
          >
            {value}
          </span>
        );
      },
    });
  }

  detailColumns.push({
    accessor: "Hours",
    header: "Hours",
    headerProps: { className: "text-end" },
    cellProps: { className: "text-end" },
    type: "number",
    Cell: (rowData) => {
      const value =
        rowData.row.endTime === "In Progress"
          ? rowData.row.endTime
          : rowData.row.hours;
      const isOtherTime = rowData.row.isOtherTime;
      return (
        <div
          className="d-flex align-items-center justify-content-end"
          data-value={value}
        >
          {isOtherTime ? (
            <>
              {rowData.row.autoGeneration ? (
                <TooltipItem
                  id="recalculate-tooltip"
                  title="Recalculate"
                  className="cursor-pointer"
                >
                  <FontAwesomeIcon
                    onClick={() => onRecalculateOtherTime(rowData.row.id)}
                    icon={faSync}
                    className="me-3 text-primary cursor-pointer"
                    data-testid="sync-icon"
                  />
                </TooltipItem>
              ) : null}
              {rowData.row.isOverwritten ? (
                <div className="d-flex align-items-center justify-content-center">
                  <TooltipItem id="overwritten-tooltip" title="Overwritten">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="me-3 text-primary"
                      data-testid="info-circle-icon-overwritten"
                    />
                  </TooltipItem>
                </div>
              ) : rowData.row.autoGeneration ? (
                <TooltipItem id="auto-tooltip" title="Auto Generated">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="me-3 text-primary cursor-pointer"
                    data-testid="info-circle-icon-auto"
                  />
                </TooltipItem>
              ) : null}
              {isSuperAdmin ? (
                <TooltipItem
                  id="billing-type-tooltip"
                  title={rowData.row.billingType}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="me-3 text-primary cursor-pointer"
                    data-testid="info-circle-icon-billing-type"
                  />
                </TooltipItem>
              ) : null}
              {isSuperAdmin && rowData.row.generationRule ? (
                <TooltipItem id="rule-tooltip" title="Generation Rule">
                  <FontAwesomeIcon
                    onClick={() => onOtherTimeInfo(rowData.row.generationRule)}
                    icon={faInfoCircle}
                    className="me-3 text-primary cursor-pointer"
                    data-testid="info-circle-icon-rule"
                  />
                </TooltipItem>
              ) : null}
            </>
          ) : null}
          <span
            onClick={() => onEditEntry(rowData.row)}
            className="text-link"
            style={{ minWidth: 50 }}
          >
            {value}
          </span>
        </div>
      );
    },
  });

  if (isTotal) {
    detailColumns.unshift({
      accessor: "Date",
      header: "Date",
      headerProps: { className: "text-start" },
      cellProps: { className: "text-start" },
      Cell: (rowData) => {
        const date = moment(rowData.row.date);
        return `${date.format("dd")} ${date.format(dateFormat)}`;
      },
    });
  } else {
    totalColumns.unshift({
      accessor: "Date",
      header: "Date",
      headerProps: { className: "text-start" },
      cellProps: { className: "text-start" },
      Cell: (rowData) => {
        return rowData.row.date;
      },
    });
  }

  const dateData = useMemo(
    () =>
      data
        ? isTotal
          ? {
              hours: Object.values(data)
                .filter((e) => typeof e === "object")
                .reduce((p, c) => p + c.hours, 0),
              rawTimes: Object.values(data)
                .filter((e) => typeof e === "object")
                .reduce((p, c) => [...p, ...c.rawTimes], []),
            }
          : data[date]
        : {},
    [data, isTotal, date]
  );

  const { hours = 0, rawTimes = [] } = dateData;

  const totalTableData = useMemo(() => {
    const formattedDate = `${moment(date).format("dd")} ${moment(date).format(
      dateFormat
    )}`;
    return [
      {
        date: formattedDate,
        totalHours: Math.max(hours, 0).toFixed(2),
      },
    ];
  }, [hours, date]);

  const detailTableData = useMemo(
    () =>
      rawTimes.map((rawTime) => {
        const data = {
          id: rawTime.id,
          date: rawTime.date || rawTime.crewWorkDay?.date || "-",
          isOtherTime: rawTime.isOtherTime,
          workOrder: rawTime.crewWorkDay?.crew.workOrder.workOrderNumber || "-",
          workOrderId: rawTime.crewWorkDay?.crew.workOrder.id || "-",
          customer:
            rawTime.crewWorkDay?.crew.workOrder.customer?.customerName || "-",
          customerId: rawTime.crewWorkDay?.crew.workOrder.customer?.id || "-",
          type: rawTime.type,
          hours: (parseFloat(rawTime.hours) || 0).toFixed(2),
        };
        if (rawTime.isOtherTime) {
          data.autoGeneration = rawTime.autoGeneration;
          data.billingType = rawTime.billingType;
          data.generationRule = rawTime.generationRule;
          data.isOverwritten = rawTime.isOverwritten;
        }
        if (startEndTimeSettingEnabled) {
          data.startTime = rawTime.startTime || "-";
          data.endTime =
            rawTime.endTime || (rawTime.startTime ? "In Progress" : "-");
        }
        return data;
      }),
    [rawTimes, startEndTimeSettingEnabled]
  );

  return editWorkTimeModal ? (
    <EditWorkTimeModal
      workTimes={[editWorkTimeModal]}
      employee={editWorkTimeModal.employee}
      onClose={() => {
        setEditWorkTimeModal();
      }}
      onSubmit={() => {
        onSubmitTimes();
        setEditWorkTimeModal();
      }}
    />
  ) : editTravelTimeModal ? (
    <EditTravelTimeModal
      travelTimes={[editTravelTimeModal]}
      employee={editTravelTimeModal.employee}
      onClose={() => {
        setEditTravelTimeModal();
      }}
      onSubmit={() => {
        onSubmitTimes();
        setEditTravelTimeModal();
      }}
    />
  ) : editOtherTimeModal ? (
    <EditOtherTimeModal
      otherTime={editOtherTimeModal}
      employee={editOtherTimeModal.employee}
      onClose={() => {
        setEditOtherTimeModal();
      }}
      onSubmit={() => {
        onSubmitTimes();
        setEditOtherTimeModal();
      }}
    />
  ) : informationModal ? (
    <InformationModal {...informationModal} />
  ) : confirmationModal ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true} onClosed={handleClose} size="xl">
      <ModalHeader
        toggle={handleClose}
        className="d-flex justify-content-between"
      >
        {dateData.employee || ""}
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Loader size="sm" />
        ) : (
          <>
            <AdvanceTable
              exportable
              exportName="PayrollDateTotals.csv"
              columns={totalColumns}
              data={totalTableData}
              pageSize={1}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
                className: "mb-0",
              }}
            />
            <AdvanceTable
              className="mt-2"
              exportable
              exportName="PayrollDateDetails.csv"
              columns={detailColumns}
              data={detailTableData}
              pageSize={Math.max(rawTimes.length, 1)}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-end" data-testid="modal-footer">
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PayrollReportDateDetail;
