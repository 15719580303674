import { useCallback } from "react";
import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const endpoint = `/api/other-time-type`;

export const useGetAllOtherTimeTypes = () => {
  return useApiGet(`${endpoint}/all`);
};

export const useGetOtherTimeTypes = () => {
  return useApiGet(endpoint);
};

export const useRegenerateOtherTimeType = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, `${endpoint}/re`);

  const regenerate = useCallback(
    async (otherTimeType) => {
      await mutate(otherTimeType);
    },
    [mutate]
  );

  return { ...rest, regenerate };
};
export const useUpdateOtherTimeType = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (otherTimeType) => {
      await mutate(otherTimeType);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useCreateOtherTimeType = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useDeleteOtherTimeType = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteOtherTimeType = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteOtherTimeType };
};
