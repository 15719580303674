import { useGetAllOtherTimeTypes } from "./api/OtherTimeTypes.hooks";

import { data, sharedHelper, helpers } from "@crewos/shared";
import OtherTimeTypes from "./pages/settings/OtherTimeTypes";
import EditOtherTimeModal from "./components/EditOtherTimeModal";
import { useRecalculateOtherTime } from "./api/OtherTimes.hooks";

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const OTHERTIMES = "OTHERTIMES";

const routes = [
  {
    sidebar: true,
    icon: null,
    name: "Other Time Types",
    path: `othertimes/other-time-types`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "othertimes",
    isSetting: true,
    category: "Other Time",
    component: OtherTimeTypes,
    order: 1,
  },
];

const Routes = createRoutes(routes);

const setOtherTimesSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "othertimes");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: OTHERTIMES, order: 6 },
    });
  }
};

export {
  Routes as OtherTimeRoutes,
  setOtherTimesSidebarRoutes,
  useGetAllOtherTimeTypes,
  useRecalculateOtherTime,
  EditOtherTimeModal,
};
