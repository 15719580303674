import React, { useEffect, useState } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from "moment";

import {
  useGetEmployeeNotes,
  useDeleteEmployeeNote,
} from "../api/EmployeesNotes.hooks";

import { components, sharedHelper } from "@crewos/shared";

import AddEmployeeNoteModal from "./AddEmployeeNoteModal";

const { Loader, ConfirmationModal } = components;

const EmployeeNotesModal = ({ onClose, employeeId }) => {
  const [refresh, setRefresh] = useState(false);
  const [notes, setNotes] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState();
  const [addNoteModal, setAddNoteModal] = useState(false);

  const {
    data: employeeNotes,
    isLoading: isLoadingEmployeeNotes,
    get: getEmployeesNotes,
  } = useGetEmployeeNotes({ employeeId });

  const { data: deleteEmployeeNoteData, deleteEmployeeNote } =
    useDeleteEmployeeNote();

  useEffect(() => {
    getEmployeesNotes();
  }, [getEmployeesNotes, refresh]);

  useEffect(() => {
    if (employeeNotes) {
      setNotes(employeeNotes);
    }
  }, [employeeNotes]);

  useEffect(() => {
    if (deleteEmployeeNoteData) {
      sharedHelper.successToast(`Note deleted`);
      setRefresh((prev) => !prev);
    }
  }, [deleteEmployeeNoteData]);

  const onDelete = (note) =>
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: () => {
        setConfirmationModal(null);
        deleteEmployeeNote(note.id);
      },
      onClose: () => {
        setConfirmationModal(null);
      },
      title: "Delete Note from Employee",
      body: `Are you sure you want to delete this note?`,
    });

  return confirmationModal ? (
    <ConfirmationModal {...confirmationModal} />
  ) : addNoteModal ? (
    <AddEmployeeNoteModal
      onSubmit={() => {
        setAddNoteModal(false);
        setRefresh((prev) => !prev);
      }}
      onClose={() => setAddNoteModal(false)}
      employeeId={employeeId}
    />
  ) : (
    <Modal scrollable={true} isOpen={true} onClosed={onClose}>
      <ModalHeader toggle={onClose} className="pb-2">
        Employee Notes
      </ModalHeader>
      <ModalBody className="text-center pt-0">
        {isLoadingEmployeeNotes ? (
          <Loader size="sm" />
        ) : (
          <Row>
            {notes?.length ? (
              <div className="d-flex w-100 flex-wrap">
                {notes.map((note) => (
                  <div className="col-6 my-1 px-1" key={note.id}>
                    <div
                      style={{ background: "#FDF1DB" }}
                      className="text-start d-flex flex-column small p-3 border-radius-md h-100"
                    >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                          <small className="text-muted">
                            {moment(note.createdAt).format(
                              "MM-DD-YYYY, HH:mm a"
                            )}
                          </small>
                          {note.employeeNoteAuthor ? (
                            <small className="text-muted">
                              {`by ${note.employeeNoteAuthor.firstName} ${note.employeeNoteAuthor.lastName}`}
                            </small>
                          ) : null}
                        </div>
                        <Button
                          className="cursor-pointer border-radius-md bg-white"
                          onClick={() => onDelete(note)}
                          data-testid="delete-button"
                        >
                          <FontAwesomeIcon
                            size="sm"
                            icon={faTrash}
                            className="text-danger"
                          />
                        </Button>
                      </div>
                      <div className="mt-3">
                        <i>{note.content}</i>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="col-12 small mb-3">
                No notes have been created
              </div>
            )}
          </Row>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between pt-2">
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button color="primary" onClick={() => setAddNoteModal(true)}>
          Add Note
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EmployeeNotesModal;
