import React, { useState, useEffect, useCallback } from "react";
import { Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  faArrowUp,
  faArrowDown,
  faSort,
  faCog,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  useSortable,
  arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { sharedHelper } from "../../helpers/sharedHelper";
import FilterModal from "./FilterModal";

let exportClassName;

const AdvanceTable = ({
  onRowClick,
  columns = [],
  data = [],
  isLoading,
  onSort,
  defaultSort = {},
  headerClassName = "",
  rowClassName = "",
  bodyClassName = "",
  tableProps = {},
  exportable = false,
  sortable = false,
  exportName = "export.csv",
  currentColumnOrder = null,
  onColumnSort = () => {},
  isColumnSortable = false,
}) => {
  const [currentSort, setCurrentSort] = useState(defaultSort);
  const [selectedFilterColumn, setSelectedFilterColumn] = useState(null);
  const [filters, setFilters] = useState({});
  const [columnOrder, setColumnOrder] = useState(
    currentColumnOrder || columns.map((col) => col.accessor)
  );

  useEffect(() => {
    if (currentColumnOrder) {
      setColumnOrder(currentColumnOrder);
    }
  }, [currentColumnOrder]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const getExportData = useCallback(() => {
    const exportHeaders = columns
      .map((column) => {
        return !column.disableExport && column.accessor !== "id"
          ? {
              label: column.header,
              key: column.accessor,
            }
          : null;
      })
      .filter(Boolean);

    const exportData = data.map((row) => {
      const formattedRow = {};
      columns
        .filter((column) =>
          exportHeaders.find(({ key }) => key === column.accessor)
        )
        .forEach((column) => {
          let dataValue;
          if (column.accessor.indexOf(".") > -1) {
            // Handle nested data
            const accessors = column.accessor.split(".");
            dataValue = accessors.reduce(
              (acc, accessor) => (acc ? acc[accessor] : row[accessor]),
              null
            );
          } else {
            dataValue = row[column.accessor];
          }
          if (moment(dataValue, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid()) {
            dataValue = sharedHelper.formatDateTime(dataValue);
          }
          if (moment(dataValue, "YYYY-MM-DD", true).isValid()) {
            dataValue = sharedHelper.formatDate(dataValue, "MM/DD/YYYY");
          }
          if (column.type === "number" && !dataValue) {
            dataValue = 0;
          }
          if (typeof dataValue === "number") {
            dataValue = dataValue.toFixed(2);
          }
          formattedRow[column.accessor] = dataValue;
        });
      return formattedRow;
    });

    return { exportHeaders, exportData };
  }, [columns, data]);

  useEffect(() => {
    if (exportable) {
      const { exportHeaders, exportData } = getExportData();

      let element;
      try {
        element = document.getElementById("table-export");
      } catch (err) {}

      if (element) {
        exportClassName = exportClassName || element.getAttribute("data-class");
        element.replaceWith(
          sharedHelper.renderComponentToNode(CSVLink, {
            id: "table-export",
            data: exportData,
            headers: exportHeaders,
            filename: exportName,
            className: `btn btn-white text-primary btn-sm rounded-circle d-flex custom-rounded-button py-2 ${
              exportClassName || ""
            }`,
            children: (
              <FontAwesomeIcon
                data-testid="fa-icon-download"
                icon={faDownload}
              />
            ),
          })
        );
      }
    }
  }, [exportable, columns, data, exportName, getExportData]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      const oldIndex = columnOrder.indexOf(active.id);
      const newIndex = columnOrder.indexOf(over.id);

      const newColumnOrder = arrayMove(columnOrder, oldIndex, newIndex);
      setColumnOrder(newColumnOrder);
      onColumnSort(newColumnOrder);
    }
  };

  const handleSort = (column) => {
    if (!sortable) return;
    if (!column.disableSortBy) {
      let newSort = { sortBy: column.accessor, direction: "asc" };

      if (currentSort.sortBy === column.accessor) {
        if (currentSort.direction === "asc") {
          newSort = { sortBy: column.accessor, direction: "desc" };
        } else if (currentSort.direction === "desc") {
          newSort = { sortBy: null, direction: null };
        }
      }

      setCurrentSort(newSort);
      onSort([newSort]);
    }
  };

  const getCellValue = (row, accessor, rowIndex, isFilter) => {
    const column = columns.find((col) => col.accessor === accessor);
    if (column && column.Cell) {
      const value = column.Cell({ row, rowIndex });
      if (isFilter) {
        if (value && value.props && value.props["data-value"]) {
          return value.props["data-value"];
        }
      }
      return value;
    }
    const value =
      accessor.indexOf(".") > -1
        ? accessor.split(".").reduce((acc, key) => acc && acc[key], row)
        : row[accessor];
    return value !== undefined && value !== null ? value : "";
  };

  const filteredData = data.filter((row, rowIndex) => {
    return Object.keys(filters).every((accessor) => {
      const filter = filters[accessor];
      const cellValue = getCellValue(row, accessor, rowIndex, true);

      if (!filter) return true;

      if (filter.mode === "selectItems") {
        if (filter.values && filter.values.length > 0) {
          return filter.values.includes(cellValue);
        }
        return false; //none selected
      } else if (filter.mode === "labelFilter") {
        const valueStr = String(cellValue).toLowerCase();
        const filterValue = filter.value.toLowerCase();

        switch (filter.condition) {
          case "contains":
            return valueStr.includes(filterValue);
          case "doesNotContain":
            return !valueStr.includes(filterValue);
          case "equals":
            return valueStr === filterValue;
          case "notEqual":
            return valueStr !== filterValue;
          case "startsWith":
            return valueStr.startsWith(filterValue);
          case "endsWith":
            return valueStr.endsWith(filterValue);
          default:
            return true;
        }
      } else if (filter.mode === "valueFilter") {
        const valueNum = parseFloat(cellValue);
        const filterValue = parseFloat(filter.value);
        const filterValueTo = parseFloat(filter.valueTo);

        switch (filter.condition) {
          case "equals":
            return valueNum === filterValue;
          case "notEqual":
            return valueNum !== filterValue;
          case "greaterThan":
            return valueNum > filterValue;
          case "lessThan":
            return valueNum < filterValue;
          case "between":
            return valueNum >= filterValue && valueNum <= filterValueTo;
          default:
            return true;
        }
      }
      return true;
    });
  });

  const applyFilter = (columnAccessor, filterData) => {
    setFilters({
      ...filters,
      [columnAccessor]: filterData,
    });
  };

  const SortableHeaderCell = ({ id, column }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: isColumnSortable && !column.disableSortBy ? "move" : "default",
      backgroundColor: isDragging ? "rgba(0,0,0,0.05)" : undefined,
      opacity: isDragging ? 0.8 : 1,
      ...(column.headerProps?.style || {}),
    };

    const className = `draggable ${column.headerProps?.className || ""} ${
      isDragging ? "is-dragging" : ""
    }`;

    return (
      <th
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={className}
        onClick={() => handleSort(column)}
        tabIndex={0}
        role="columnheader"
        aria-grabbed={isDragging}
      >
        {!column.disableFilter ? (
          <FontAwesomeIcon
            data-testid={`fa-icon-gear-${column.accessor}`}
            icon={faCog}
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedFilterColumn(column);
            }}
            style={{ cursor: "pointer", marginRight: "5px" }}
          />
        ) : null}
        {column.header}
        {sortable && !column.disableSortBy ? (
          <span
            className={`ms-1 sort ${
              currentSort.sortBy === column.accessor
                ? currentSort.direction
                : ""
            }`}
          >
            {currentSort.sortBy === column.accessor ? (
              currentSort.direction === "desc" ? (
                <FontAwesomeIcon className="text-dark" icon={faArrowDown} />
              ) : (
                <FontAwesomeIcon className="text-dark" icon={faArrowUp} />
              )
            ) : (
              <FontAwesomeIcon className="text-dark" icon={faSort} />
            )}
          </span>
        ) : null}
      </th>
    );
  };

  return (
    <>
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <Table
          {...tableProps}
          className={`overflow-hidden advance-table ${tableProps.className}`}
        >
          <thead className={headerClassName}>
            {isColumnSortable ? (
              <SortableContext
                items={columnOrder}
                strategy={horizontalListSortingStrategy}
              >
                <tr>
                  {columnOrder.map((accessor) => {
                    const column = columns.find(
                      (col) => col.accessor === accessor
                    );
                    return column ? (
                      <SortableHeaderCell
                        key={column.accessor}
                        id={column.accessor}
                        column={column}
                      />
                    ) : null;
                  })}
                </tr>
              </SortableContext>
            ) : (
              <tr>
                {columnOrder.map((accessor) => {
                  const column = columns.find(
                    (col) => col.accessor === accessor
                  );
                  return column ? (
                    <th
                      key={column.accessor}
                      onClick={() => handleSort(column)}
                      className={column.headerProps?.className}
                      style={{
                        cursor: column.disableSortBy ? "default" : "pointer",
                        ...(column.headerProps?.style || {}),
                      }}
                    >
                      {!column.disableFilter ? (
                        <FontAwesomeIcon
                          data-testid={`fa-icon-gear-${column.accessor}`}
                          icon={faCog}
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedFilterColumn(column);
                          }}
                          style={{ cursor: "pointer", marginRight: "5px" }}
                        />
                      ) : null}
                      {column.header}
                      {sortable && !column.disableSortBy ? (
                        <span
                          className={`ms-1 sort ${
                            currentSort.sortBy === column.accessor
                              ? currentSort.direction
                              : ""
                          }`}
                        >
                          {currentSort.sortBy === column.accessor ? (
                            currentSort.direction === "desc" ? (
                              <FontAwesomeIcon
                                className="text-dark"
                                icon={faArrowDown}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="text-dark"
                                icon={faArrowUp}
                              />
                            )
                          ) : (
                            <FontAwesomeIcon
                              className="text-dark"
                              icon={faSort}
                            />
                          )}
                        </span>
                      ) : null}
                    </th>
                  ) : null;
                })}
              </tr>
            )}
          </thead>
          <tbody className={`condensed ${bodyClassName}`}>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  Loading...
                </td>
              </tr>
            ) : filteredData.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="text-center small text-muted"
                >
                  No data to display
                </td>
              </tr>
            ) : (
              filteredData.map((row, rowIndex) => (
                <tr
                  data-testid={`table-row-${row.id}`}
                  key={rowIndex}
                  className={`${rowClassName || ""} ${
                    onRowClick ? "cursor-pointer" : ""
                  }`}
                  onClick={onRowClick ? () => onRowClick(row) : null}
                >
                  {columnOrder.map((accessor) => {
                    const column = columns.find(
                      (col) => col.accessor === accessor
                    );
                    return column ? (
                      <td
                        key={column.accessor}
                        className={column.cellProps?.className}
                      >
                        {getCellValue(row, column.accessor, rowIndex)}
                      </td>
                    ) : null;
                  })}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </DndContext>
      {selectedFilterColumn ? (
        <FilterModal
          toggle={() => setSelectedFilterColumn(null)}
          columnAccessor={selectedFilterColumn.accessor}
          columnHeader={selectedFilterColumn.header}
          columnValues={[
            ...new Set(
              data
                .map((row, rowIndex) =>
                  getCellValue(
                    row,
                    selectedFilterColumn.accessor,
                    rowIndex,
                    true
                  )
                )
                .filter((v) => v != null)
            ),
          ]}
          onApply={applyFilter}
          currentFilter={filters[selectedFilterColumn.accessor]} // Pass the current filter
        />
      ) : null}
    </>
  );
};

export default AdvanceTable;
