import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  useGetOtherTimeTypes,
  useDeleteOtherTimeType,
} from "../../api/OtherTimeTypes.hooks";

import { Card, CardBody, CardHeader, Container, Button } from "reactstrap";

import { faSync } from "@fortawesome/free-solid-svg-icons";

import { components } from "@crewos/shared";

import OtherTimeTypeModal from "../../components/OtherTimeTypeModal";

const { AdvanceTable, AdvanceTablePagination, ConfirmationModal } = components;

const columns = (onDelete, onEdit) => [
  {
    accessor: "name",
    header: "Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.name;
    },
  },
  {
    accessor: "shortName",
    header: "Short Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.shortName;
    },
  },
  {
    accessor: "billingType",
    header: "Billing Type",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    type: "number",
    Cell: (rowData) => {
      return rowData.row.billingType;
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    cellProps: {
      className: "text-end",
    },
    headerProps: {
      className: "text-truncate text-end",
      style: { minWidth: 300 },
    },
    Cell: (rowData) => (
      <>
        <Button
          size="sm"
          color="none"
          className="mx-2 text-primary"
          onClick={() => onEdit(rowData.row)}
        >
          Edit
        </Button>
        <Button
          size="sm"
          className="text-danger"
          color="none"
          onClick={() => onDelete(rowData.row)}
        >
          Delete
        </Button>
      </>
    ),
  },
];

const PAGE_SIZE = 10;
const INIT_PAGE = 1;

const OtherTimeTypes = () => {
  const [OtherTimeTypes, setOtherTimeTypes] = useState({});
  const [refresh, setRefresh] = useState();
  const [sizePerPage, setSizePerPage] = useState(PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);

  const [createModal, setCreateModal] = useState();
  const [updateModal, setUpdateModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: getOtherTimeTypesData,
    isLoading: isLoadingGetOtherTimeTypes,
    get: getOtherTimeTypes,
  } = useGetOtherTimeTypes();

  useEffect(() => {
    getOtherTimeTypes({
      page: page - 1,
      pageSize: sizePerPage,
    });
  }, [getOtherTimeTypes, refresh, page, sizePerPage]);

  useEffect(() => {
    if (getOtherTimeTypesData) {
      setOtherTimeTypes(getOtherTimeTypesData);
    }
  }, [getOtherTimeTypesData, setOtherTimeTypes]);

  const { data: deleteOtherTimeTypeData, deleteOtherTimeType } =
    useDeleteOtherTimeType();

  useEffect(() => {
    if (deleteOtherTimeTypeData) {
      setRefresh((prev) => !prev);
    }
  }, [deleteOtherTimeTypeData]);

  const onEdit = (OtherTimeType) => {
    setUpdateModal(OtherTimeType);
  };

  const onDelete = (OtherTimeType) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal();
        deleteOtherTimeType(OtherTimeType.id);
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Delete Other Time Type",
      body: `Are you sure you want to delete ${OtherTimeType.name}?`,
      confirmColor: "primary",
    });
  };

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Card className="mb-3 w-100 box-shadow-none">
        <CardHeader className="pt-2 pb-3 d-flex align-items-center justify-content-between">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Other Time Types</h2>
            <small className="text-muted ms-2 pt-1">
              ({OtherTimeTypes.count})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="p-4">
          <div className="overflow-x-auto">
            <AdvanceTable
              columns={columns(onDelete, onEdit)}
              data={OtherTimeTypes.data || []}
              pageSize={10}
              isLoading={isLoadingGetOtherTimeTypes}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={OtherTimeTypes.count}
            pageCount={OtherTimeTypes.totalPages}
            currentPage={page - 1}
            onPageChange={(page) => setPage(page)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : createModal ? (
        <OtherTimeTypeModal
          onClose={() => setCreateModal()}
          onSubmit={() => {
            setCreateModal();
            setRefresh((prev) => !prev);
          }}
        />
      ) : updateModal ? (
        <OtherTimeTypeModal
          otherTimeType={updateModal}
          onClose={() => setUpdateModal()}
          onSubmit={() => {
            setUpdateModal();
            setRefresh((prev) => !prev);
          }}
        />
      ) : null}
    </Container>
  );
};

export default OtherTimeTypes;
