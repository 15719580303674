import React, { useEffect, useState } from "react";

import {
  faExchangeAlt,
  faSync,
  faInfoCircle,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  Input,
} from "reactstrap";

import { toast } from "react-toastify";

import {
  components,
  api,
  sharedHelper,
  toastConfig,
  data,
} from "@crewos/shared";

import { syncApi } from "../../api/syncServices";

import { DebounceInput } from "react-debounce-input";

const { useAuth, AUTH_ACTIONS } = data;

const { useGetSettings, useUpdateSetting } = api;

const { AdvanceTable, TooltipItem, ConfirmationModal, UploadPhotosModal } =
  components;

const BRANDING_LOGO = "BRANDING_LOGO";
const LOGIN_LOGO = "LOGIN_LOGO";

const columns = (onChangeSettingStatus, onChangeSettingValue) => [
  {
    accessor: "name",
    header: "Setting",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const value = rowData.row.name;
      return (
        <div data-value={value} className="d-flex align-items-center">
          {value}
          {rowData.row.description ? (
            <div className="ms-2">
              <TooltipItem
                id={`${rowData.row.id}-tooltip`}
                title={rowData.row.description}
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-primary"
                  style={{ paddingBottom: "1px" }}
                />
              </TooltipItem>
            </div>
          ) : null}
        </div>
      );
    },
  },
  {
    accessor: "status",
    header: "Status",
    disableFilter: true,
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) =>
      !rowData.row.valueOnly ? (
        <Input
          checked={rowData.row.status}
          onChange={(event) =>
            onChangeSettingStatus(rowData.row, event.currentTarget.checked)
          }
          type="switch"
          data-testid="status-switch"
          id={rowData.row.id}
          name={rowData.row.id}
          label={rowData.row.status ? "Enabled" : "Disabled"}
          className="cursor-pointer d-flex align-items-center small setting-switch"
        />
      ) : null,
  },
  {
    accessor: "value",
    header: "Value",
    disableFilter: true,
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { value, status } = rowData.row;
      if (!rowData.row.statusOnly) {
        return (
          <DebounceInput
            debounceTimeout={900}
            disabled={rowData.row.valueReadOnly}
            placeholder={`${rowData.row.valuePlaceholder || "value"}...`}
            className="form-control"
            data-testid={`setting-value-${rowData.row.id}`}
            maxLength="50"
            type="text"
            value={value}
            onChange={(e) => onChangeSettingValue(rowData.row, e.target.value)}
          />
        );
      }
      return status ? value || "-" : "-";
    },
  },
];

const Settings = () => {
  const [, setAuthContext] = useAuth();
  const [settings, setSettings] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState();
  const [updateSidebarLogoModal, setUpdateSidebarLogoModal] = useState();
  const [updateLoginLogoModal, setUpdateLoginLogoModal] = useState();

  const {
    data: settingsData,
    isLoading: isLoadingSettings,
    get: getSettings,
  } = useGetSettings();

  useEffect(() => {
    getSettings({
      packageId: null, //core
    });
  }, [getSettings, refresh]);

  useEffect(() => {
    if (settingsData) {
      setSettings(settingsData);
    }
  }, [settingsData]);

  const {
    data: updateSettingData,
    isLoading: isLoadingUpdateSetting,
    update: updateSettings,
  } = useUpdateSetting();

  useEffect(() => {
    if (updateSettingData) {
      setRefresh((prev) => !prev);
      sharedHelper.successToast(`Setting saved`);
    }
  }, [updateSettingData]);

  const onChangeSettingStatus = (setting, status) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        setConfirmationModal();
        updateSettings({
          ...setting,
          status,
        });
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Update setting status",
      body: `Are you sure you want to change this setting status?`,
    });
  };

  const onChangeSettingValue = (setting, value) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        setConfirmationModal();
        updateSettings({
          ...setting,
          value,
        });
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Update setting value",
      body: `Are you sure you want to change this setting value?`,
    });
  };

  const onSync = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal();
        toast.promise(
          async () => {
            try {
              const res = await syncApi.doSync();
              toast.success(
                `Success! Imported ${res.workOrders} work orders and ${res.customers} customers.`,
                {
                  ...toastConfig,
                  toastId: "success",
                }
              );
            } catch (error) {
              toast.error(`There was an error with your request`, {
                ...toastConfig,
                toastId: "error",
              });
            }
          },
          {
            pending: "Loading...",
          },
          toastConfig
        );
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Ben Parker Sync",
      body: `<span class="text-center">
          This will trigger a synchronization of Work Orders, & Customers data, new data will be added. Do you want to continue?
        </span>`,
      confirmColor: "warning",
    });
  };

  const onUploadLogo = (url, settingName) => {
    const setting = settings.find((setting) => setting.name === settingName);
    if (!setting) {
      return sharedHelper.errorToast("Setting not found");
    }
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        setUpdateSidebarLogoModal();
        setConfirmationModal();
        await updateSettings({
          ...setting,
          value: url,
        });
        setAuthContext({
          action: AUTH_ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Update logo",
      body: `Are you sure you want to change the logo?`,
    });
  };

  const nonInternalSettings = settings.filter((setting) => !setting.isInternal);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Card className="mb-3 w-100 box-shadow-none">
        <CardHeader className="pt-2 pb-3 d-flex align-items-center justify-content-between">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Core Settings</h2>
            <small className="text-muted ms-2 pt-1">({settings.length})</small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="me-3 d-flex align-items-center"
              color="primary"
              onClick={() => setUpdateSidebarLogoModal(true)}
            >
              <FontAwesomeIcon style={{ paddingBottom: 2 }} icon={faUpload} />
              <span className="ms-2">Sidebar/PDF Logo</span>
            </Button>
            <Button
              size="sm"
              className="me-3 d-flex align-items-center"
              color="primary"
              onClick={() => setUpdateLoginLogoModal(true)}
            >
              <FontAwesomeIcon style={{ paddingBottom: 2 }} icon={faUpload} />
              <span className="ms-2">Login Logo</span>
            </Button>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              data-testid="refresh-button"
              onClick={() => setRefresh((prev) => !prev)}
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
            <TooltipItem id="sync-tooltip" title="Sync BR BP WOs & Customers">
              <Button
                size="sm"
                className="mr-3 rounded-circle d-flex custom-rounded-button py-2"
                color="primary"
                onClick={onSync}
              >
                <FontAwesomeIcon icon={faExchangeAlt} size="sm" />
              </Button>
            </TooltipItem>
          </div>
        </CardHeader>
        <CardBody className="d-flex flex-column overflow-x-auto p-4">
          <AdvanceTable
            columns={columns(onChangeSettingStatus, onChangeSettingValue)}
            data={nonInternalSettings}
            pageSize={nonInternalSettings.length || 1}
            isLoading={isLoadingSettings || isLoadingUpdateSetting}
            headerClassName="text-muted small"
            tableProps={{
              striped: true,
              className: "mb-0",
            }}
          />
        </CardBody>
      </Card>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : updateSidebarLogoModal ? (
        <UploadPhotosModal
          title={"Upload PDF & Sidebar Logo"}
          onClose={() => setUpdateSidebarLogoModal()}
          onSubmit={(data) => onUploadLogo(data.attachmentUrl, BRANDING_LOGO)}
        />
      ) : updateLoginLogoModal ? (
        <UploadPhotosModal
          title={"Upload Login Logo"}
          onClose={() => setUpdateLoginLogoModal()}
          onSubmit={(data) => onUploadLogo(data.attachmentUrl, LOGIN_LOGO)}
        />
      ) : null}
    </Container>
  );
};

export default Settings;
