import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import AssignTemplateWOModal from "./AssignTemplateWOModal";
import { data, components } from "@crewos/shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  useGetTemplateWorkOrder,
  useDeleteInspectionTemplateWorkOrder,
} from "../api/Inspections.hooks";

const { useWorkOrderDetails } = data;
const { AdvanceTable, AdvanceTablePagination, ConfirmationModal } = components;

const columns = ({ onDelete }) => [
  {
    accessor: "template",
    header: "Template",
    disableSortBy: true,
    disableFilter: true,
    headerProps: { className: "text-truncate text-start" },
    Cell: (rowData) => {
      const { template } = rowData.row;
      return template.name || "-";
    },
  },
  {
    accessor: "asset",
    header: "Asset",
    disableSortBy: true,
    disableFilter: true,
    headerProps: { className: "text-truncate text-start" },
    Cell: (rowData) => {
      const { name } = rowData.row;
      return name || "-";
    },
  },
  {
    accessor: "records",
    header: "Records",
    disableSortBy: true,
    disableFilter: true,
    headerProps: { className: "text-truncate text-start" },
    Cell: (rowData) => {
      const { inspections } = rowData.row;
      return inspections.length || "0";
    },
  },
  {
    accessor: "id",
    header: "",
    disableSortBy: true,
    disableFilter: true,
    headerProps: { style: { minWidth: 300 } },
    Cell: (rowData) => {
      const { inspections } = rowData.row;
      return inspections.length === 0 ? (
        <Button
          color="none"
          className="ms-2 text-danger px-2 d-flex align-items-center float-end"
          size="sm"
          onClick={() => onDelete(rowData.row)}
        >
          <FontAwesomeIcon className="mx-2" icon={faTrash} />
        </Button>
      ) : null;
    },
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 1;

const CrewsInspectionsTemplates = () => {
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [workOrderDetails] = useWorkOrderDetails();

  const {
    data: templateWOData,
    isLoading: isLoadingTemplateWO,
    get: getTemplateWO,
  } = useGetTemplateWorkOrder();

  const { mutate: deleteInspectionTemplateWorkOrder } =
    useDeleteInspectionTemplateWorkOrder();

  const handleGetTemplatesWO = useCallback(
    async ({ searchText, currentPage, currentSizePerPage }) => {
      await getTemplateWO({
        workOrderId: workOrderDetails.workOrder.id,
        search: searchText,
        page: currentPage - 1,
        pageSize: currentSizePerPage,
      });
    },
    [workOrderDetails, getTemplateWO]
  );

  const handleAssignTemplate = useCallback(() => {
    setShowAssignModal(false);
    handleGetTemplatesWO({
      searchText: search,
      currentPage: page,
      currentSizePerPage: sizePerPage,
    });
  }, [handleGetTemplatesWO, search, page, sizePerPage]);

  const handleOpenAssignTemplate = useCallback(() => {
    setShowAssignModal(true);
  }, []);

  const handleDelete = useCallback(
    (templateWORecord) => {
      setConfirmationModal({
        isOpen: true,
        onSubmit: async () => {
          setConfirmationModal();
          await deleteInspectionTemplateWorkOrder({ id: templateWORecord.id });
          await handleGetTemplatesWO({
            searchText: search,
            currentPage: page,
            currentSizePerPage: sizePerPage,
          });
        },
        onClose: () => {
          setConfirmationModal();
        },
        title: "Remove Assignation",
        body: "Are you sure you want to remove the template from this work order?",
        confirmColor: "primary",
      });
    },
    [
      deleteInspectionTemplateWorkOrder,
      handleGetTemplatesWO,
      search,
      page,
      sizePerPage,
    ]
  );

  const handlePageChange = useCallback(
    (newPage) => {
      setPage(newPage);
      handleGetTemplatesWO({
        currentPage: newPage,
        currentSizePerPage: sizePerPage,
        searchText: search,
      });
    },
    [handleGetTemplatesWO, search, sizePerPage]
  );

  const handleSizePerPageChange = useCallback(
    (newSizePerPage) => {
      setSizePerPage(newSizePerPage);
      setPage(INIT_PAGE);
      handleGetTemplatesWO({
        currentPage: INIT_PAGE,
        currentSizePerPage: newSizePerPage,
        searchText: search,
      });
    },
    [handleGetTemplatesWO, search]
  );

  const handleSearch = useCallback(
    (e) => {
      setSearch(e.target.value);
      setPage(INIT_PAGE);
      handleGetTemplatesWO({
        currentPage: INIT_PAGE,
        currentSizePerPage: sizePerPage,
        searchText: e.target.value,
      });
    },
    [handleGetTemplatesWO, sizePerPage]
  );

  useEffect(() => {
    handleGetTemplatesWO({
      currentPage: INIT_PAGE,
      currentSizePerPage: sizePerPage,
      searchText: search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templatesWO = useMemo(() => {
    return templateWOData || {};
  }, [templateWOData]);

  return (
    <>
      <Card className="border-top-left-radius-0 overflow-hidden">
        <CardBody className="row border-top-left-radius-0">
          <h2 className="col-6">Inspection Templates</h2>
          <div className="col-6 row align-items-center">
            <div className="col-8">
              <DebounceInput
                className="search border-0 form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search"
                value={search}
                onChange={handleSearch}
              />
            </div>
            <Button
              color="primary"
              className="col-4"
              onClick={handleOpenAssignTemplate}
            >
              Assign Template
            </Button>
          </div>
        </CardBody>
      </Card>
      <Card className="border-top-left-radius-0 overflow-hidden">
        <CardBody className="border-top-left-radius-0">
          <AdvanceTable
            isLoading={isLoadingTemplateWO}
            headerClassName="text-muted small"
            columns={columns({ onDelete: handleDelete })}
            data={templatesWO.data || []}
            pageSize={sizePerPage}
            sortable
            tableProps={{
              striped: true,
            }}
          />
          <AdvanceTablePagination
            totalCount={templatesWO.count}
            pageCount={templatesWO.totalPages}
            currentPage={page - 1}
            onPageChange={handlePageChange}
            pageSize={sizePerPage}
            onPageSizeChange={handleSizePerPageChange}
          />
        </CardBody>
      </Card>
      {showAssignModal && (
        <AssignTemplateWOModal
          isOpen={showAssignModal}
          workOrder={workOrderDetails.workOrder}
          onAssign={handleAssignTemplate}
          onClose={() => setShowAssignModal(false)}
        />
      )}
      {confirmationModal && <ConfirmationModal {...confirmationModal} />}
    </>
  );
};

export default CrewsInspectionsTemplates;
