import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  Label,
} from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import {
  useUpdateOtherTime,
  useDeleteOtherTime,
} from "../api/OtherTimes.hooks";

const { Loader } = components;

const EditOtherTimeModal = ({ otherTime, employee, onClose, onSubmit }) => {
  const [localEntry, setLocalEntry] = useState({ ...otherTime });

  const {
    isLoading: isLoadingUpdateOtherTime,
    update: updateOtherTime,
    data: updateOtherTimeData,
  } = useUpdateOtherTime();

  const { data: deleteOtherTimeData, deleteOtherTime } = useDeleteOtherTime();

  useEffect(() => {
    if (updateOtherTimeData || deleteOtherTimeData) {
      sharedHelper.successToast(
        `Other times ${updateOtherTimeData ? "updated" : "deleted"}`
      );
      onSubmit(localEntry);
    }
  }, [updateOtherTimeData, deleteOtherTimeData, onSubmit, localEntry]);

  const onOtherTimeChanged = (data, field, otherTime) => {
    const editedOtherTime = {
      ...otherTime,
      [field]: data,
      touched: true,
      deleted: false,
    };
    setLocalEntry(editedOtherTime);
  };

  const onOtherTimeDeleted = (otherTime) => {
    const deletedOtherTime = {
      ...otherTime,
      deleted: true,
      touched: false,
    };
    setLocalEntry(deletedOtherTime);
  };

  const doSubmit = async (event) => {
    event.preventDefault();

    if (!localEntry.touched && !localEntry.deleted) {
      sharedHelper.successToast(`No changes applied`);
      return onClose();
    }

    if (localEntry.touched) {
      updateOtherTime({ ...localEntry });
    } else if (localEntry.deleted) {
      deleteOtherTime({ ...localEntry });
    }
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        <div className="d-flex flex-column align-items-start">
          <span>Edit Other Time</span>
        </div>
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="mt-n3">
          {!localEntry.deleted ? (
            <div className="d-flex flex-column align-items-center justify-content-between py-3">
              <div className="d-flex align-items-center justify-content-between col-12 px-0 mb-1">
                <div className="d-flex align-items-center justify-content-start">
                  <small className="text-muted me-2">Employee:</small>
                  <small>{`${employee.firstName} ${employee.lastName}`}</small>
                </div>
                <Button
                  className="float-end d-flex align-items-center"
                  color="none"
                  size="sm"
                  onClick={() => onOtherTimeDeleted(otherTime)}
                  data-testid="delete-button"
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="text-danger"
                    size="lg"
                  />
                </Button>
              </div>
              <div
                className="d-flex align-items-start flex-column py-2 col-12 px-0"
                tag="div"
              >
                <Label>
                  <small>Hours</small>
                  <small className="ms-1 text-danger">*</small>
                </Label>
                <input
                  className="form-control-redesign"
                  type="number"
                  placeholder="Enter the mileage"
                  required={true}
                  max={24}
                  min={0}
                  step={0.1}
                  value={localEntry.hours}
                  onChange={(event) =>
                    onOtherTimeChanged(
                      event.currentTarget.value,
                      "hours",
                      localEntry
                    )
                  }
                />
              </div>
            </div>
          ) : (
            <div className="col-12 px-0 text-muted text-center mt-3">
              No time entries to show
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Discard
          </Button>
          {isLoadingUpdateOtherTime || isLoadingUpdateOtherTime ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button type="submit" color="primary">
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditOtherTimeModal;
