import React, { useState, useMemo } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import moment from "moment";
import {
  QueryBuilder,
  defaultOperators,
  toFullOption,
  formatQuery,
} from "react-querybuilder";
import { QueryBuilderBootstrap } from "@react-querybuilder/bootstrap";
import "react-querybuilder/dist/query-builder.css";
import "bootstrap-icons/font/bootstrap-icons.scss";
import { sharedHelper } from "../helpers/sharedHelper";
import CustomCheckboxWithLabel from "./CustomCheckboxWithLabel";
import DatesSelector from "./DatesSelector";
import { useAuth } from "../providers/authProvider";

const EXCLUDE_OPERATORS = [
  "contains",
  "beginsWith",
  "endsWith",
  "doesNotContain",
  "doesNotBeginWith",
  "doesNotEndWith",
  "null",
  "notNull",
];

const MAX_TRAVEL_ENTRIES = 10;

const OPERATORS = defaultOperators.filter(
  (op) => !EXCLUDE_OPERATORS.includes(op.value)
);

const TRAVEL_TIME_PACKAGE = "traveltimes";
const WORK_TIME_PACKAGE = "worktimes";

const validator = (r) => !!r.value;

const RulesModal = ({
  entity,
  defaultRule,
  workTimeTypes = [],
  onClose,
  onSubmit,
}) => {
  const [authContext] = useAuth();

  const isEdit = defaultRule && typeof defaultRule === "object";

  const [rule, setRule] = useState(
    isEdit ? defaultRule.query : { combinator: "and", rules: [] }
  );

  const [travelEntries, setTravelEntries] = useState(
    isEdit ? defaultRule.travelEntries : 0
  );

  const [dates, setDates] = useState(
    isEdit
      ? {
          startDate: defaultRule.startDate || moment().format("YYYY-MM-DD"),
          endDate: defaultRule.endDate || moment().format("YYYY-MM-DD"),
        }
      : {
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        }
  );

  const [name, setName] = useState(isEdit ? defaultRule.name : "");

  const [openDates, setOpenDates] = useState(
    isEdit ? !defaultRule.startDate || !defaultRule.endDate : true
  );

  const [precedence, setPrecedence] = useState(
    isEdit ? defaultRule.precedence : ""
  );

  const [amount, setAmount] = useState(isEdit ? defaultRule.amount : "");

  const travelTimePackageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    TRAVEL_TIME_PACKAGE
  );

  const workTimePackageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    WORK_TIME_PACKAGE
  );

  const fields = useMemo(() => {
    const travelFields = travelTimePackageEnabled
      ? [
          {
            name: `allTravelHours`,
            label: `Sum all travel hours`,
            placeholder: "Enter hours",
            operators: OPERATORS,
            validator,
          },
          {
            name: `allTravelMiles`,
            label: `Sum all travel miles`,
            placeholder: "Enter miles",
            operators: OPERATORS,
            validator,
          },
          {
            name: `allTravelDrives`,
            label: `Sum all travel drives`,
            placeholder: "Enter drives",
            operators: OPERATORS,
            validator,
          },
          ...Array.from(Array(parseInt(travelEntries)).keys()).flatMap(
            (index) => [
              {
                name: `travel${index + 1}Hours`,
                label: `Travel ${index + 1} hours`,
                placeholder: "Enter hours",
                operators: OPERATORS,
                validator,
              },
              {
                name: `travel${index + 1}Miles`,
                label: `Travel ${index + 1} miles`,
                placeholder: "Enter miles",
                operators: OPERATORS,
                validator,
              },
              {
                name: `travel${index + 1}Driver`,
                label: `Is Travel ${index + 1} driver`,
                valueEditorType: "checkbox",
                operators: OPERATORS.filter((op) => op.value === "="),
                defaultValue: false,
              },
            ]
          ),
        ]
      : [];
    const workFields = workTimePackageEnabled
      ? [
          {
            name: `allWorkHours`,
            label: `Sum all work hours`,
            placeholder: "Enter hours",
            operators: OPERATORS,
            validator,
          },
          ...workTimeTypes.map((workTimeType) => ({
            name: `${workTimeType.shortName}WorkHours`,
            label: `${workTimeType.name} work hours`,
            placeholder: "Enter hours",
            operators: OPERATORS,
            validator,
          })),
        ]
      : [];
    const combinedFields = [...travelFields, ...workFields];
    return combinedFields.map((o) => toFullOption(o));
  }, [
    workTimeTypes,
    travelEntries,
    travelTimePackageEnabled,
    workTimePackageEnabled,
  ]);

  const doSubmit = (evt) => {
    evt.preventDefault();
    if (!openDates && !dates.startDate) {
      return sharedHelper.warningToast(
        "Start date is required when open dates is disabled"
      );
    }
    if (!openDates && !dates.endDate) {
      return sharedHelper.warningToast(
        "End date is required when open dates is disabled"
      );
    }
    if (rule.rules.find((rule) => rule.value === "")) {
      return sharedHelper.warningToast("All values are required");
    }
    onSubmit({
      name,
      travelEntries,
      query: rule,
      condition: formatQuery(rule, "cel"),
      startDate: openDates ? null : dates.startDate,
      endDate: openDates ? null : dates.endDate,
      precedence,
      amount,
      index: defaultRule?.index,
    });
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader className="d-flex justify-content-between" toggle={onClose}>
        {`${isEdit ? "Edit" : "Create"} ${entity} Rule`}
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <FormGroup>
            <Label>
              <span>Rule Name</span>
              <span className="text-danger ms-1">*</span>
            </Label>
            <input
              className="form-control-redesign"
              maxLength="50"
              type="text"
              min={0}
              placeholder="Enter the name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>
              <span>Precedence</span>
              <span className="text-danger ms-1">*</span>
            </Label>
            <input
              className="form-control-redesign"
              type="number"
              min={0}
              placeholder="Enter the precedence"
              value={precedence}
              onChange={(e) => setPrecedence(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>
              <span>{entity} Generated</span>
              <span className="text-danger ms-1">*</span>
            </Label>
            <input
              className="form-control-redesign"
              type="number"
              min={0}
              step={0.1}
              placeholder={`Enter the ${entity.toLowerCase()} generated`}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </FormGroup>
          {!openDates ? (
            <div className="d-flex align-items-center justify-content-end w-100">
              <DatesSelector
                defaultStartDate={dates.startDate}
                defaultEndDate={dates.endDate}
                onSubmit={(startDate, endDate) => {
                  setDates({
                    startDate,
                    endDate,
                  });
                }}
              />
            </div>
          ) : null}
          <FormGroup>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Label>
                <span>Rule</span>
                <span className="text-danger ms-1">*</span>
              </Label>
              {travelTimePackageEnabled ? (
                <FormGroup noMargin className="d-flex align-items-center small">
                  <Label className="flex-shrink-0 mb-0 me-2">
                    <span>Daily TT Entries (Up to)</span>
                  </Label>
                  <input
                    className="border-0 small text-muted"
                    type="number"
                    min={0}
                    max={MAX_TRAVEL_ENTRIES}
                    placeholder="Enter the TT entries"
                    value={travelEntries}
                    onChange={(e) => setTravelEntries(e.target.value)}
                    required
                  />
                </FormGroup>
              ) : null}
            </div>
            <QueryBuilderBootstrap>
              <QueryBuilder
                fields={fields}
                query={rule}
                onQueryChange={setRule}
                showCombinatorsBetweenRules
              />
            </QueryBuilderBootstrap>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <div className="d-flex align-items-center justify-content-center">
            <Button
              color="secondary"
              onClick={onClose}
              className="me-2 text-dark"
            >
              Discard
            </Button>
            <CustomCheckboxWithLabel
              label="Open Dates"
              className="btn btn-info"
              color="info"
              checked={openDates}
              onClick={() => setOpenDates(!openDates)}
            />
          </div>
          <Button color="primary" type="submit" name="save">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default RulesModal;
